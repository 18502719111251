// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  loginUrl: '/api/login',
  signUpUrl: '/sign-up/register',
  auth0Url: 'auth.dev.opengamma.com',
  audience: 'https://api.dev.opengamma.com',
  analyticsUrl: 'https://analytics.dev.opengamma.com',
  jsccTenantIds: [
    { tenant: "J19", name: "TEST1"},
    { tenant: "J20", name: "TEST2"}
  ]
};
