/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';
import AuthOptions = auth0.AuthOptions;

/** Auth0 implementation of Auth service. */
@Injectable()
export class Auth0AuthService extends AuthService {

  redirectToProvider(connection: string, clientId: string): void {
    const webAuth = new auth0.WebAuth({
      audience: environment.audience,
      domain: environment.auth0Url,
      clientID: clientId
    } as AuthOptions);

    webAuth.authorize({
      connection: connection,
      clientID: clientId,
      redirectUri: environment.analyticsUrl,
      audience: environment.audience,
      scope: 'openid profile email name',
      responseType: 'token'
    });
  }

  postSamlResponse(connection: string, samlResponse: string): void {
    // We're submitting a form without using Angular's HttpClient to avoid CORS
    // errors. POSTing using the HttpClient involves an AJAX request, which requires
    // the destination to respond with the "Access-Control-Allow-Origin" header.
    //
    // > "For security reasons, browsers restrict cross-origin HTTP requests initiated from scripts.
    // > For example, XMLHttpRequest and the Fetch API follow the same-origin policy. This means
    // > that a web application using those APIs can only request resources from the same origin the
    // > application was loaded from unless the response from other origins includes the right CORS
    // > headers."
    // > https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
    //
    // The Assertion Consumer Service does not seem to respond to OPTIONS requests.
    const acsUrl = `https://${environment.auth0Url}/login/callback?connection=${connection}`;
    const form = window.document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', acsUrl);

    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', 'SAMLResponse');
    hiddenField.setAttribute('value', samlResponse);
    hiddenField.setAttribute('type', 'hidden');
    form.appendChild(hiddenField);

    window.document.body.appendChild(form);
    form.submit();
  }

}
