/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

/** Contains authentication-related methods. */
export abstract class AuthService {

  /** Logs users into the Analytics website using SAML. */
  abstract redirectToProvider(connection: string, clientId: string): void;

  /** Posts the raw SAML response to the connection's Assertion Consumer Service URL. */
  abstract postSamlResponse(connection: string, samlResponse: string): void;

}
