/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Auth0AuthService } from './services/auth0-auth.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: AuthService, useFactory: authServiceFactory }
  ]
})
export class UserAuthModule {}

// -------------------------------------------------------------------------
/** Provides the auth data service. */
export function authServiceFactory(): AuthService {
  return new Auth0AuthService();
}
