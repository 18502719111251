import {AfterContentInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { AuthService } from 'app/user-auth/services/auth.service';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
  encapsulation: ViewEncapsulation.None // needed to style injected HTML
})
export class LogInComponent implements AfterContentInit, OnDestroy {
  @ViewChild('userEmail', { static: false }) userEmail: ElementRef;

  email: string;
  loginMessage: string;
  rememberMe: boolean;
  private redirectUrl: string;
  private organization: string;

  message: SafeHtml;
  isSubmitting = false;
  private focused = false;
  private routeDataSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    const queryParams = parseQueryString(window.location.search.substr(1));

    this.rememberMe = localStorage.getItem('rememberMe') !== 'false';
    this.email = queryParams['userEmail'] || localStorage.getItem('email') || '';
    this.redirectUrl = queryParams['redirectUrl'];
    this.routeDataSubscription = this.activatedRoute.data.subscribe(routeData => {
      this.loginMessage = routeData.loginMessage;
      this.organization = queryParams['tenantId'] || routeData.organization;
    })

    const connection = queryParams['connection'];
    const samlResponse = queryParams['samlResponse'];
    if (connection && samlResponse) {
      // Log into tenants by accepting unsolicited SAML responses
      authService.postSamlResponse(connection, samlResponse);
    }
  }

  ngOnDestroy(): void {
    this.routeDataSubscription.unsubscribe();
  }

  isTextTyped(): boolean {
    return this.email && this.email.length > 0;
  }

  onChangeFocus(focus: boolean) {
    this.focused = focus;
  }

  isValidInput(): boolean {
    return this.userEmail && this.userEmail.nativeElement.validity.valid;
  }

  ngAfterContentInit() {
    if (this.userEmail) {
      this.userEmail.nativeElement.focus();
    }
  }

  labelOpacity(): number {
    if (this.isTextTyped()) {
      return 0;
    }
    if (this.focused) {
      return 0.5;
    }
    return 1;
  }

  submit(form) {
    if (!this.isValidInput()) {
      return;
    }

    if (this.rememberMe) {
      localStorage.setItem('email', form.value.email);
    } else {
      localStorage.removeItem('email');
    }
    localStorage.setItem('rememberMe', (`${this.rememberMe}`));

    this.isSubmitting = true;
    this.http.post(environment.loginUrl, {
      email: form.value.email,
      redirect: this.redirectUrl,
      organization: this.organization
    }).subscribe((data: any) => {

      if (!data.errorMessage) {
        this.message = this.sanitizer.bypassSecurityTrustHtml(data.message);

        if (isSamlLogin(data)) {
          this.authService.redirectToProvider(data['connection'], data['clientId']);
        }
      }

      this.isSubmitting = false;
    });
  }

}

function isSamlLogin(data: any): boolean {
  return 'connection' in data && 'clientId' in data;
}

function parseQueryString(query): { [key: string]: string } {
  const vars = query.split('&');
  const queryString = {};
  for (const param of vars) {
    const pair = param.split('=');
    // If first entry with this name
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof queryString[pair[0]] === 'string') {
      const arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
      queryString[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      queryString[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return queryString;
}
