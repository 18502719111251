/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EnvironmentConfig, SignUpEnvironment } from 'app/sign-up/sign-up.model';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  firstName: string;
  lastName: string;
  company: string;
  jobTitle: string;
  email: string;
  consent: boolean;
  jsccTermsConsent: boolean;
  tenantId: string = environment.jsccTenantIds[0].tenant
  selectedEnvironment: SignUpEnvironment;

  private redirectUrl: string;

  message: string;
  contactEmail: string;
  isSubmitting = false;
  showSuccessPendingMessage = false;

  jsccTermsConditionsLink = `${environment.analyticsUrl}/assets/jscc-terms.pdf`;

  _environmentConfig: EnvironmentConfig;
  @Input() set environmentConfig(config: EnvironmentConfig) {
    this._environmentConfig = config;
    const queryParams = parseQueryString(window.location.search.substr(1));
    const tenantParam = queryParams['tenantId'] || config.defaultEnvironment.queryParam;
    this.selectedEnvironment = config.signUpEnvironments.find(env => env.queryParam === tenantParam) || config.defaultEnvironment;
    this.email = queryParams['email'] || '';
    this.redirectUrl = queryParams['redirectUrl'];
  }

  constructor(private http: HttpClient) {
  }

  submit() {
    this.isSubmitting = true;
    this.http.post(environment.signUpUrl, {
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        jobTitle: this.jobTitle,
        email: this.email,
        marketingOptIn: this.consent || false,
        environment: this.tenantId || this.selectedEnvironment.tenantId,
        ...(this.selectedEnvironment.jsccTermsConsentRequired && {
          jsccTermsConsent: this.jsccTermsConsent,
        }),
    }).subscribe((data: any) => {
          this.isSubmitting = false;
          if (data.signupResponse == 'SUCCESS') {
        this.http.post(environment.loginUrl, {
            email: this.email,
            redirect: this.redirectUrl,
            organization: this.tenantId || this.selectedEnvironment.tenantId
        }).subscribe((data: any) => {
                this.message = data.errorMessage || "Please check your inbox as you will shortly receive a link to log in.";
              });
          } else if (data.signupResponse == 'SUCCESS_PENDING') {
            if (environment.jsccTenantIds.map(jsccTenantId => jsccTenantId.tenant).includes(this.tenantId)) {
              this.showSuccessPendingMessage = true;
              this.message = undefined;
            } else {
              this.message = "Sorry, your email doesn't appear to be on our pre-approved list.";
            }
          } else {
            this.message = 'Something went wrong. If this issue persists please contact';
            this.contactEmail = 'support@opengamma.com';
          }
    }, (error) => {
          this.message = 'Something went wrong. If this issue persists please contact';
          this.contactEmail = 'support@opengamma.com';
    });
  }

  getTodayPlusOneWeek() {
    const now = new Date();
    now.setDate(now.getDate() + 7);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return now.toLocaleDateString("en-US", options);
  }

  protected readonly environment = environment;
}

// TODO this is duplicated
function parseQueryString(query) {
  const vars = query.split('&');
  const query_string = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    // If first entry with this name
    if (typeof query_string[pair[0]] === 'undefined') {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof query_string[pair[0]] === 'string') {
      const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
      query_string[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return query_string;
}
