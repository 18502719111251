<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<main [style.background-image]="background">

  <router-outlet [style.z-index]="3"></router-outlet>

  <div class="code">
    <div class="dots" *ngFor="let _ of dots; let x = index">
      <div
        class="dot" *ngFor="let _ of yIterator(x); let y = index"
        [style.animation]="dotStyle(x, y)"
      ></div>
    </div>
  </div>
</main>

