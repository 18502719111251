/*
 * Copyright (C) 2022 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { SignUpEnvironment, EnvironmentConfig } from 'app/sign-up/sign-up.model';

@Component({
  selector: 'og-demo-sign-up',
  templateUrl: './demo-sign-up.component.html'
})
export class DemoSignUpComponent {

  private demoBuySideEnvironment: SignUpEnvironment = {
    name: 'Demo Buy Side',
    tenantId: 'd16',
    queryParam: 'demobuyside',
    isDemo: true
  };

  environmentConfig: EnvironmentConfig = {
    defaultEnvironment: this.demoBuySideEnvironment,
    signUpEnvironments: [
      this.demoBuySideEnvironment,
      { name: 'Demo Sell Side', tenantId: 'd17', queryParam: 'demosellside', isDemo: true },
      { name: 'Demo Asset Manager', tenantId: 'd18', queryParam: 'demoassetmanager', isDemo: true }
    ],
    displayLoginLink: true
  };

}
