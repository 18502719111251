/*
 * Copyright (C) 2022 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { SignUpEnvironment, EnvironmentConfig } from 'app/sign-up/sign-up.model';

@Component({
  selector: 'og-jscc-sign-up',
  templateUrl: './jscc-sign-up.component.html'
})
export class JsccSignUpComponent {
  private environment: SignUpEnvironment = {
    name: 'JSCC',
    isDemo: false,
    jsccTermsConsentRequired: true
  };
  environmentConfig: EnvironmentConfig = {
    defaultEnvironment: this.environment,
    signUpEnvironments: [this.environment],
    panelText: 'Sign up for JSCC VaR Margin Simulations',
    displayLoginLink: false
  };
}
