<img class="logo" src="assets/open-logo-white.svg">

<div class="container" *ngIf="!message">

  <div class="login-message">{{loginMessage}}</div>

  <form
    class="content"
    (submit)="submit(loginForm)"
    id="form"
    #loginForm="ngForm"
  >
    <div class="content__envelope-container">
      <img class="content__envelope" src="assets/envelope.svg">
    </div>
    <input
      autofocus
      [(ngModel)]="email"
      class="content__input"
      (focus)="onChangeFocus(true)"
      (blur)="onChangeFocus(false)"
      type="email"
      id="email"
      name="email"
      spellcheck="false"
      #userEmail
      required pattern=.*\S.*.>

    <label
      [style.opacity]="labelOpacity()"
      class="content__label"
      for="email"
    >Log in with your email</label>
    <ng-container
      *ngIf="!isSubmitting; else isFormSubmitted"
    >
      <button
        *ngIf="isTextTyped()"
        [disabled]="!isValidInput()"
        class="content__button"
        type="submit"
        id="submit"
        (click)="submit(loginForm)"
      >
        Continue
      </button>
    </ng-container>
    <ng-template #isFormSubmitted>
      <button
        class="content__button content__button--loading"
      >
        <div class="og-spinner-container">
          <div class="og-spinner"></div>
        </div>
      </button>
    </ng-template>
  </form>

  <div class="save-email">
    <label class="save-email__label" for="saveEmail">Remember me</label>
    <div class="checkbox">
      <input [(ngModel)]="rememberMe" type="checkbox" value="None" id="saveEmail" name="check" checked/>
      <label for="saveEmail"></label>
    </div>
  </div>

</div>

<div *ngIf="message" class="message" [innerHtml]="message"></div>
