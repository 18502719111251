/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'og-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  readonly background = `url("/assets/backgrounds/OG_Spectrum_${Date.now() % 8}.jpg")`;
  readonly dotsDimension = 20;
  readonly dots = new Array(this.dotsDimension);

  dotStyles: string[][] = [];

  yIterator(x) {
    return new Array(x);
  }

  dotStyle(x: number, y: number): string {
    if (this.dotStyles[x] && this.dotStyles[x][y]) {
      return this.dotStyles[x][y]
    }

    // Dots further away from the corner should be (on average) less visible.
    const time = 5 + Math.random() * y * x;
    let fade = Math.min(20, x - y);
    if ((Math.random() * 20) + y + (20 - x) > 20) {
      fade = 20;
    }
    const value = `fade${fade} ${time}s ease infinite`;
    this.dotStyles[x] = this.dotStyles[x] || [];
    this.dotStyles[x][y] = value;
    return value;
  }

  constructor(titleService:Title, router:Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = router.routerState.snapshot.root.children[0].data.title;
        titleService.setTitle(title);
      }
    });
  }
}
